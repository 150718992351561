'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import { useSWRConfig } from 'swr';
import { Formik, FormikProps, Field } from 'formik';
import * as Yup from 'yup';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import Link from 'next/link';

import { useUserDispatch } from '../../context/user-context';
import { COLORS } from '../../constants/styles';
import {
  FieldBox,
  Input,
  Label,
  Error as FormError,
  ServerError,
} from '../styled/form';
import { Button, Sending } from '../styled/button';
import Spinner from '../TailSpin';

import classes from './signin.module.scss';

interface Props {
  redirect?: string;
}

interface CreateUserValues {
  email: string;
  password: string;
}

type ErrorWithMessage = {
  message: string;
};

const schema = Yup.object({
  email: Yup.string()
    .required('Es necesario rellenar este campo con tu email')
    .email(
      'Este email no parece ser valido. Por fvor asegúrate de haberlo escrito bien.'
    ),
  password: Yup.string().required('Falta añadir tu contraseña'),
});

const SignIn = ({ redirect }: Props) => {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { dispatch } = useUserDispatch();
  const [error, setError] = React.useState<ErrorWithMessage | null>();
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);

  const signin = async (
    email: string,
    password: string,
    redirect?: string
  ): Promise<void> => {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });

    if (response.status !== 200) {
      throw new Error(await response.text());
    }

    if (redirect) {
      router.push(redirect);
    }
  };

  async function submit(values: CreateUserValues): Promise<void> {
    setError(null);
    const { email, password } = values;

    try {
      await signin(email, password, redirect);
      dispatch({
        type: 'setError',
        error: null,
      });
      mutate('/api/profile');
    } catch (error) {
      console.error(error);
      setError({ message: 'Error signing in' });
    }
  }

  function handlePasswordVisibility(): void {
    setIsPasswordHidden(!isPasswordHidden);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values: CreateUserValues) => submit(values)}
        validationSchema={schema}
      >
        {({
          isSubmitting,
          handleSubmit,
          errors,
          touched,
          values,
        }: FormikProps<CreateUserValues>) => (
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
            method="post"
          >
            <Field name="email">
              {({ field }) => (
                <FieldBox>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    {...field}
                    hasValue={values.email}
                    hasError={touched.email && errors.email}
                  />
                  {touched.email && errors.email && (
                    <FormError>{errors.email}</FormError>
                  )}
                </FieldBox>
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FieldBox>
                  <Label>Contraseña</Label>
                  <Input
                    type={isPasswordHidden ? 'password' : 'text'}
                    {...field}
                    hasValue={values.password}
                    hasError={touched.password && errors.password}
                  />
                  {touched.password && errors.password && (
                    <FormError>{errors.password}</FormError>
                  )}
                  <button
                    className={classes.passwordButton}
                    type="button"
                    onClick={handlePasswordVisibility}
                  >
                    {values.password && isPasswordHidden && (
                      <IconEye color={COLORS.dark} />
                    )}
                    {values.password && !isPasswordHidden && (
                      <IconEyeClosed color={COLORS.dark} />
                    )}
                  </button>
                </FieldBox>
              )}
            </Field>

            <div className={classes.actions}>
              <Button variant="action" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <Sending>
                    <Spinner color={COLORS.dark} /> Enviando{' '}
                  </Sending>
                ) : (
                  'Enviar'
                )}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      {error && (
        <div className={classes.errors}>
          <ServerError>
            Ups, algo ha ido mal
            <span>
              Por favor comprueba que los datos sean correctas y si necesitas
              ayuda no dudes en <Link href="/contacto">contactarme</Link>.
            </span>
          </ServerError>
        </div>
      )}
    </>
  );
};

export { SignIn };
