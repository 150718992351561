import NextLink, { LinkProps } from 'next/link';
import clsx from 'clsx';
import { ReactNode } from 'react';
import classes from './button.module.scss';
import { poppins } from './text';

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  type?: 'submit' | 'button';
  disabled?: boolean;
  variant?: 'action' | 'alert' | 'caution';
  parentStyles?: string;
}

type LinkButtonProps = {
  variant?: 'action' | 'secondary';
  type?: 'narow';
};

export const Button = ({
  children,
  type = 'button',
  disabled = false,
  onClick,
  variant,
  parentStyles,
}: React.PropsWithChildren<ButtonProps>) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={clsx(
        classes.button,
        poppins.className,
        variant === 'action' && classes.action,
        parentStyles && parentStyles
      )}
    >
      {children}
    </button>
  );
};

type ButtonLinkProps = LinkProps &
  LinkButtonProps & {
    children: ReactNode;
    contextStyleClass?: string;
    css?: never;
  };

export const ButtonLink = ({
  variant,
  type,
  children,
  contextStyleClass,
  ...props
}: ButtonLinkProps) => (
  <NextLink
    className={clsx(
      classes.buttonLink,
      variant === 'action' && classes.action,
      type && classes.narow,
      contextStyleClass && contextStyleClass
    )}
    {...props}
  >
    {children}
  </NextLink>
);

type SendingProps = {
  children: React.ReactNode;
  contextStyleClass?: string;
};

export const Sending = ({ children, contextStyleClass }: SendingProps) => (
  <span
    className={clsx(classes.sending, contextStyleClass && contextStyleClass)}
  >
    {children}
  </span>
);
