import clsx from 'clsx';

import classes from './form.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasValue?: boolean;
  hasError?: boolean;
  parentStyles?: string;
}

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasValue?: boolean;
  hasError?: boolean;
}

interface LabelProps {
  optional?: boolean;
  children: React.ReactNode;
  parentStyles?: string;
  htmlFor?: string;
}

type FormErrorProps = {
  children: React.ReactNode;
  parentStyles?: string;
};

export const FieldBox = ({ children }) => (
  <div className={classes.fieldBox}>{children}</div>
);

export const Input = ({
  hasError,
  hasValue,
  parentStyles,
  ...props
}: InputProps) => (
  <input
    className={clsx(
      classes.input,
      hasError && classes.hasError,
      hasValue && classes.hasValue,
      parentStyles && parentStyles
    )}
    {...props}
  />
);

export const Label = ({
  children,
  optional,
  parentStyles,
  htmlFor,
}: LabelProps) => (
  <label
    htmlFor={htmlFor}
    className={clsx(
      classes.label,
      optional && classes.optional,
      parentStyles && parentStyles
    )}
  >
    {children}
  </label>
);

export const Error = ({ children, parentStyles }: FormErrorProps) => (
  <span className={clsx(classes.error, parentStyles && parentStyles)}>
    {children}
  </span>
);

export const Actions = ({ children }) => (
  <div className={classes.actions}>{children}</div>
);

export const ServerError = ({ children }) => (
  <div className={classes.serverError}>{children}</div>
);

export const TextArea = ({ hasError, hasValue, ...props }: TextAreaProps) => (
  <textarea
    className={clsx(
      classes.textarea,
      hasError && classes.hasError,
      hasValue && classes.hasValue
    )}
    {...props}
  />
);
