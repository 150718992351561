export const MAX_LAYOUT_WIDTH = 1680;

const breakpoints = [600, 900, 1200, 1680];

export const MQ = breakpoints.map(bp => `@media (min-width: ${bp}px)`);
export const MQ_MAX = breakpoints.map(bp => `@media (max-width: ${bp - 1}px)`);

export const COLORS = {
  attention0: '#FFF9E8',
  attention1: 'hsl(48, 100%, 90%)',
  attentionActive: 'hsl(48, 100%, 75%)',
  attention: '#ffda05',
  attentionHover: 'hsl(51, 100%, 46%)',
  attentionDark1: 'hsl(51, 100%, 45%)',
  bottle: '#075D52',
  bottleLight: '#F1FFFE',
  potus: '#00A69C',
  error: '#f25c5a',
  errorBackground: 'hsl(1, 85%, 90%)',
  errorActive: 'hsl(1, 85%, 55%)',
  red5: '#af110e', //hsl(1, 85%, 37%),
  dark3: '#cfdbe2',
  dark2: 'hsl(202, 25%, 45%)',
  dark: '#1E2E28',
  green: 'hsl(161, 18%, 69%)',
  greenText: 'hsl(161, 18%, 45%)',
  lightGreen: 'hsl(86, 21%, 94%)',
  pink: '#f5e8e3',
  lightGrey: 'hsl(228,24%,95%)',
  gray0: '#ECF4F1',
  gray020: 'rgba(236, 244, 241, .2)',
  gray020NoAlpha: '#fcfcfc',
  gray2: '#B2C2BC',
  gray3: '#76807C',
  gray4: '#C9DDD6',
  gray0Hover: '#dbebe5',
  gray: '#9fa09f',
  white: '#FFFFFF',
  white0: '#FFFEF9',
  black: '#000000',
  red1: '#fdd0cf',
  teja: '#DE8069',
  tejaHover: 'hsl(12 64% 44%)',
  tejaLight: '#FFDFDF',
  marmolClaro: '#FFECEA',
};
